import { GetOwnDepartmentsResponseDepartments } from '@/src/api/generated';
import { CheckboxList } from '@/src/components/blocks/CheckboxList';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';
import { AllToggleAvailableFormProps } from '@/src/types/form';

import styles from './styles.module.scss';

export type ModalInputMap = {
  ownDepartments: string[];
};

type Props = {
  form: Omit<
    AllToggleAvailableFormProps<ModalInputMap, 'ownDepartments'>,
    'isDisabled'
  >;
  isShown: boolean;
  onClose: VoidFunction;
  ownDepartmentOptions: GetOwnDepartmentsResponseDepartments[];
  isSp: boolean;
};

export const OwnDepartmentFilterModalPresenter: React.FC<Props> = (props) => {
  return (
    <ModalDialog
      isShown={props.isShown}
      size="wide"
      title="診療科目"
      okButton={{
        children: '保存する',
        onClick: props.form.onSubmit,
      }}
      cancelButton={{
        children: '閉じる',
        onClick: props.onClose,
        color: 'basic',
      }}
      subActionButton={{
        children: props.form.ownDepartments.isAllSelected
          ? props.form.ownDepartments.allClear.label
          : props.form.ownDepartments.allSelect.label,
        onClick: props.form.ownDepartments.isAllSelected
          ? props.form.ownDepartments.allClear.onClick
          : props.form.ownDepartments.allSelect.onClick,
      }}
    >
      <form>
        <div className={styles.checkboxesWrapper}>
          <CheckboxList
            options={props.ownDepartmentOptions.map((o) => ({
              label: o.value,
              value: o.key,
            }))}
            gap={{ row: 16, column: 16 }}
            isVertical={props.isSp}
            {...props.form.ownDepartments}
          />
        </div>
      </form>
    </ModalDialog>
  );
};
