import styles from './styles/styles.module.scss';

import clsx from 'clsx';
import { useRef, useState, useEffect } from 'react';
import { DatePicker, DatePickerProps } from 'rsuite';

type Props = {
  value?: Date;
  placement?: 'bottomStart' | 'bottomEnd';

  disabledDate: (date?: Date) => boolean;
  isInForm?: boolean;
  // OKボタンで、日付を確定しなくても、選択した日付が反映される
  oneTap?: boolean;
  hasError?: boolean;
} & (NotCleanableSpecificProps | CleanableSpecificProps) &
  OverrideDatePickerProps;

type NotCleanableSpecificProps = {
  onChange: (value: Date) => void;
  onClean?: never;
};
type CleanableSpecificProps = {
  onChange: (value?: Date) => void;
  onClean: VoidFunction;
};

type OverrideDatePickerProps = Pick<
  DatePickerProps,
  'placeholder' | 'disabled'
>;

type DatePickerInstance = {
  // APIと型定義として公開されていないが、DatePickerのインスタンス経由で呼び出すために
  close: VoidFunction;
} & HTMLDivElement;

export const MonthPicker: React.FC<Props> = (props) => {
  const datePickerRef = useRef<DatePickerInstance>(null);
  const handleDateSelect = (validDate: Date) => {
    props.onChange(validDate);
    datePickerRef.current?.close();
  };

  const [value, setValue] = useState<Date | null>(null);

  // NOTE: valueをコンポーネント内で管理しないと、受け渡すだけでは onClean 発火時に rsuite の DatePicker 内で、 value 更新制御が走らないケースがある。。。
  useEffect(() => {
    if (props.value?.getTime() !== value?.getTime()) {
      setValue(props.value ? new Date(props.value) : null);
    }
  }, [props.value]);

  return (
    <DatePicker
      ref={datePickerRef}
      editable={false}
      placeholder={props.placeholder ?? 'yyyy-MM'}
      appearance="subtle"
      cleanable={!!props.onClean}
      format="yyyy-MM"
      className={clsx(
        props.isInForm ? styles.isInForm : styles.isDefaultForm,
        props.hasError && styles.hasError,
        {
          [styles.disabled]: props.disabled,
        },
      )}
      ranges={[]}
      placement={props.placement ?? 'bottomStart'}
      value={value}
      onChange={(date) => {
        if (props.onClean) {
          props.onChange(date ?? undefined);
          return;
        }

        // onCleanがない かつ、 date が null のケースは、無効とみなす
        if (date === null) return;
        props.onChange(date);
      }}
      // 選択可能な日付が選択されたとき発火する。oneTapで選択したときは、okで確定しなくてもonChangeが呼ばれるようにする
      onSelect={(validDate) => props.oneTap && handleDateSelect(validDate)}
      onClean={() => props.onClean && props.onClean()}
      disabledDate={props.disabledDate}
      // 現在 v4.29 では不具合で、選択した日付が反映されないが、propを渡すことで、okButtonのfooterが非表示になる
      oneTap={props.oneTap}
      disabled={props.disabled}
      locale={{
        sunday: '日',
        monday: '月',
        tuesday: '火',
        wednesday: '水',
        thursday: '木',
        friday: '金',
        saturday: '土',
        formattedMonthPattern: 'yyyy-MM',
        ok: '適用',
      }}
    />
  );
};
