import { BarChartTooltip } from '@/src/components/foundations/Charts/BarChartTooltip';
import { CHART_PROPERTIES, useChart } from '@/src/hooks/useChart';
import { ChartProps, ChartWithValues } from '@/src/types/chart';

import styles from './styles.module.scss';

import React from 'react';
import {
  TooltipProps,
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Surface,
  Symbols,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export type TooltipContentProps = TooltipProps<ValueType, NameType>;

type BarChartTooltipProps = React.ComponentProps<typeof BarChartTooltip>;

type StackIdGroup = {
  [key: string]: ChartWithValues;
};

type Props = {
  data: {
    name: string;
    stacks: { [key: string]: number };
    onClick?: VoidFunction;
  }[];
  mode: 'simple' | 'stack' | 'mix' | 'mixStack';
  keys?: string[];
  tooltip?: React.ComponentProps<typeof Tooltip>;
  shortenNameLength?: boolean;
} & ChartProps;

export const BarChart: React.FC<Props> = (props) => {
  const { createPayload } = useChart();

  const shortenNameLength = (label: string) => {
    return label.length >= 6 ? label.slice(0, 5) + '...' : label;
  };

  return (
    <ReBarChart
      width={props.size.width}
      height={props.size.height + props.size.legendHeight}
      data={props.data.map((d) => {
        return {
          name: d.name,
          // 偶然onClickとcursorを設定することができることを見つけた
          // rechartのAPIリファレンスでどこにこのspecの記載があるかは見つけられなかった
          onClick: d.onClick,
          cursor: d.onClick ? 'pointer' : 'default',
          ...d.stacks,
        };
      })}
      margin={{ top: 0, right: 0, bottom: 0, left: 8 }}
      style={{ fontSize: '14px' }}
      className={styles.barChart}
    >
      <XAxis
        axisLine={CHART_PROPERTIES.xaxislLne}
        tickLine={CHART_PROPERTIES.yaxisLine}
        padding={{ right: 8, left: 8 }}
        dataKey="name"
        tickMargin={6}
        interval={props.shortenNameLength ? 0 : 'preserveEnd'}
        tickFormatter={(label: string) =>
          props.shortenNameLength ? shortenNameLength(label) : label
        }
      />

      <YAxis
        axisLine={CHART_PROPERTIES.xaxislLne}
        tickLine={CHART_PROPERTIES.yaxisLine}
        tickMargin={6}
      />
      <Tooltip
        content={(data) => {
          if (data.active && data.payload && data.payload.length > 0) {
            const barChartTooltipProps: BarChartTooltipProps = {
              title: String(data.label),
              items: data.payload.map((p) => ({
                label: String(p.name),
                value: Number(p.value),
                color: p.color,
              })),
            };

            // NOTE: items の要素順をを逆にして表示
            if (props.mode === 'stack') {
              return (
                <BarChartTooltip
                  title={barChartTooltipProps.title}
                  items={barChartTooltipProps.items.reverse()}
                />
              );
            }

            // NOTE: stackId ごとにグループされたオブジェクトを作成し、
            // そのグループ内の要素順を逆にして表示
            if (props.mode === 'mixStack') {
              const stackIdGroup: StackIdGroup = {};

              props.charts.forEach((c, index) => {
                if (c.stackId) {
                  if (!stackIdGroup[c.stackId]) {
                    stackIdGroup[c.stackId] = [];
                  }
                  stackIdGroup[c.stackId].push({
                    ...c,
                    value: barChartTooltipProps.items[index].value,
                  });
                }
              });

              return (
                <BarChartTooltip
                  title={barChartTooltipProps.title}
                  items={Object.keys(stackIdGroup).flatMap((key) =>
                    stackIdGroup[key]
                      .map((item) => ({
                        label: item.name,
                        color: item.color,
                        value: item.value,
                      }))
                      .reverse(),
                  )}
                />
              );
            }

            return <BarChartTooltip {...barChartTooltipProps} />;
          }
          return null;
        }}
        {...props.tooltip}
      />
      <CartesianGrid vertical={false} />

      {(() => {
        switch (props.mode) {
          case 'simple':
            return props.charts.map((c) => (
              <Bar key={c.key} dataKey={c.key} name={c.name} fill={c.color} />
            ));
          case 'stack':
            return props.charts.map((c) => (
              <Bar
                key={c.key}
                dataKey={c.key}
                name={c.name}
                fill={c.color}
                stackId=""
              />
            ));
          case 'mix':
            return props.charts.map((c) => (
              <Bar
                key={c.key}
                dataKey={c.key}
                name={c.name}
                fill={c.color}
                stackId={c.name}
              />
            ));
          case 'mixStack':
            return props.charts.map((c) => (
              <Bar
                key={c.key}
                dataKey={c.key}
                name={c.name}
                fill={c.color}
                stackId={c.stackId}
              />
            ));
        }
      })()}
      <Legend
        chartHeight={props.size.height}
        payload={createPayload(props.payload)}
        wrapperStyle={{
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
        // NOTE: 選択された項目デザインをカスタマイズしています。
        content={(() => (
          <ul className={styles.legend}>
            {props.payload?.map((entry, index) => (
              <li key={index} className={styles.listItem}>
                <Surface width={16} height={16} className={styles.surface}>
                  <Symbols
                    cx={8}
                    cy={8}
                    size={260}
                    type="square"
                    fill={entry.color}
                  />
                </Surface>
                <span className={styles.text}>{entry.value}</span>
              </li>
            ))}
          </ul>
        ))()}
      />
    </ReBarChart>
  );
};
