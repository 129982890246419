import styles from './styles.module.scss';

type Props = {
  title: string;
  items: {
    label: string;
    value: number;
    color?: string;
  }[];
};

export const BarChartTooltip: React.FC<Props> = (props) => (
  <div className={styles.base}>
    <p>{props.title}</p>
    <ul>
      {props.items.map((item, index) => (
        <li
          key={index}
          className={styles.listItem}
          style={{ color: item.color }}
        >
          {item.label} : {item.value}
        </li>
      ))}
    </ul>
  </div>
);
